
import Header from "./header";

const SolarEnergyHeader = () => {
  const title_str = "SOLAR ENERGY";
  const subtitle_str = [`최대값은 550, 최소값은 250으로 범위가 한정됩니다. 5초마다 데이터가 업데이트되고, 총 2개 센서로부터 데이터를 받습니다.`];

  return <Header title={title_str} subtitle={subtitle_str} />;
};

export default SolarEnergyHeader;

