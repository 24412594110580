import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/menu/dashboard";
import Others from "./scenes/menu/others";
import { ColorModeContext, useMode } from "./contexts/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import DashboardHeader from "./scenes/headers/dashboard";
import { Box } from "@mui/material";
import ViewerComponent from "./forge/ViewerComponent";
// import { useState, useEffect } from "react";
import { useState } from "react";
// import io from "socket.io-client";
import OthersHeader from "./scenes/headers/others";

import AirQuality from "./scenes/menu/air_quality";
import AirQualityHeader from "./scenes/headers/air_quality";
import SolarEnergyHeader from "./scenes/headers/solar_energy";
import SolarEnergy from "./scenes/menu/solar_energy";
import WaterDection from "./scenes/menu/water_detection";
import WaterDetectionHeader from "./scenes/headers/water_detection";
import HumidityHeader from "./scenes/headers/humidity";
import Humidity from "./scenes/menu/humidity";
import TemperatureHeader from "./scenes/headers/temperature";
import Temperature from "./scenes/menu/temperature";
import VibrationHeader from "./scenes/headers/vibration";
import Vibration from "./scenes/menu/vibration";

// Socket IO 설정
// const socket = io("http://localhost:8787", {transports: ['websocket', 'polling']});

function App() {
  // Socket IO 관련 설정
  // useEffect(() => {
  //   socket.on("data", (data) => {
  //     setData(data)
  //     // console.log(data);
  //   });

  //   return () => {
  //     socket.off("data");
  //   };
  // }, []);

  // const [data, setData] = useState(undefined);
  const [data] = useState(undefined);

  // UI 디자인(색상 및 css) 관련 설정
  const [theme, colorMode] = useMode();

  // Forge API 사용을 위한 설정
  const [viewController, setViewController] = useState(undefined);
  const isolateComponents = (component_array) => {
    if (viewController !== undefined) {
      // console.log("YES");
      viewController.isolate(component_array);
      viewController.select(component_array);
    } else {
      console.log("viewer is undefined");
    }
  };

  // useEffect(() => {
  //   // 데이터 가져오기 예제
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("http://localhost:8787/api/data", {
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       });
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const result = await response.json();
  //       setData(result);
  //     } catch (error) {
  //       console.error("Failed to fetch data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* 서비스의 사이드바 */}
          <div className="SideBar"> 
            <Sidebar isolation={isolateComponents}/>
          </div>
          {/* 오른쪽 부분 */}
          <div className="RightBar">
            <div className="content">
              {/* 서비스의 헤더 */}
              <Topbar />

              {/* 각 패널에 대한 설명 */}
              <Box m="20px">
                {/* <Box onClick={()=>isolateComponents([5386, 5501, 5380])}>ISOLAATION</Box>
                <Box onClick={()=>isolateComponents([])}>SHOWALL</Box> */}
                <Routes>
                  <Route path="/" element={<DashboardHeader />} />
                  <Route path="/airquality" element={<AirQualityHeader />} />
                  <Route path="/solarenergy" element={<SolarEnergyHeader />} />
                  <Route path="/waterdetection" element={<WaterDetectionHeader />} />
                  <Route path="/humidity" element={<HumidityHeader />} />
                  <Route path="/temperature" element={<TemperatureHeader />} />
                  <Route path="/vibration" element={<VibrationHeader />} />
                  <Route path="/others" element={<OthersHeader />} />
                </Routes>

                {/* 데이터 시각화 */}
                <Box display="flex" gap="20px" paddingBottom="30px">
                  <Box className="main-graph" sx={{width: '750px !important'}}>
                    <Routes>
                      <Route path="/" element={<Dashboard data={data}/>} />
                      <Route path="/airquality" element={<AirQuality data={data}/>} />
                      <Route path="/solarenergy" element={<SolarEnergy data={data}/>} />
                      <Route path="/waterdetection" element={<WaterDection data={data}/>} />
                      <Route path="/humidity" element={<Humidity data={data}/>} />
                      <Route path="/temperature" element={<Temperature data={data}/>} />
                      <Route path="/vibration" element={<Vibration data={data}/>} />
                      <Route path="/others" element={<Others />} />
                    </Routes>
                  </Box>

                  {/* FORGE 모델 */}
                  <Box>
                    <ViewerComponent className="main-3D" setViewController={setViewController} />
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
