import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../contexts/theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WaterRoundedIcon from "@mui/icons-material/WaterRounded";
import WaterDropRoundedIcon from "@mui/icons-material/WaterDropRounded";
import AirRoundedIcon from "@mui/icons-material/AirRounded";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import DeviceThermostatRoundedIcon from "@mui/icons-material/DeviceThermostatRounded";
import VibrationRoundedIcon from "@mui/icons-material/VibrationRounded";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Item = ({ title, to, icon, selected, setSelected, isolated, compList }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === to}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(to)
        isolated(compList)
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

// function ChangeToGlobalID(numList){
  
// };

const Sidebar = ({isolation}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    const currentRoute = window.location.pathname;
    setSelected(currentRoute);
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Box></Box>
                {/* <Typography variant="h5" color={colors.grey[700]} mt="5px">
                  ADMIN PAGE
                </Typography> */}
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* USER */}
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-logo"
                  width="100px"
                  height="100px"
                  src={`../../assets/logo.png`}
                />
              </Box>

              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  AUTODESK
                </Typography>
                <Typography variant="h6" color={colors.greenAccent[400]}>
                  Monitoring System
                </Typography>
              </Box>
            </Box>
          )}

          {/* MENU ITEMS */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[]}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="공기질 데이터"
              to="/airquality"
              icon={<AirRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[27927, 27826, 27935, 27833, 27944, 27843, 27951, 27840, 27959, 27854, 27967, 27861, 27975, 27868, 27983, 27847, 
                27875, 27991, 27882, 28000, 27869, 28008, 27889, 27896, 28015, 27903, 28023, 27910, 28031, 27917, 28038, 28080, 28033, 28088 ]} // rvt 파일에서 위치 알려주는 리스트
              // client\src\forge\extensions\LoggerExtension.js 파일에서 console로 나옴 "이벤트~~~"
            />
            <Item
              title="태양광 데이터"
              to="/solarenergy"
              icon={<WbSunnyRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[46132, 46017, 45902, 45787, 45672, 44522, 44637, 44752, 44867, 44982, 43717, 43832, 43802, 43487, 43372, 46247,
                46362, 46477, 46592, 46707, 45557, 45442, 45327, 45212, 45097, 43947, 44062, 44177, 44292, 44407, 43257, 43142, 43027, 
                42912, 42797, 41647, 41762, 41877, 41992, 42107, 42682, 42567, 42452, 42337, 4222, 41072, 41187, 41302, 40267, 28882, 
                28767, 28652, 28537, 28422, 29572, 29687, 29802, 29917, 30032, 31182, 31067, 30952, 30837, 30722, 31872, 31987, 32102, 
                32217, 32332, 33482, 33367, 33252, 33137, 33022, 34172, 34287, 34402, 34517, 34632, 35782, 35552, 35437, 35322, 35667, 
                36932, 36817, 36702, 36587, 36472, 38082, 37967, 37852, 37737, 37622, 28997, 29112, 29227, 29342, 29457, 30607, 30377, 
                30492, 30262, 30147, 31297, 31412, 31527, 31642, 31757, 32907, 32792, 32677, 32562, 32447, 33597, 33712, 33827, 33942,
                34057, 34747, 34862
               ]} 
              //4133 -> 378616
            />
            <Item
              title="물감지 데이터"
              to="/waterdetection"
              icon={<WaterDropRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[ 3723, 3726, 3729, 4017, 4020, 4023 ]}
            />
            <Item
              title="습도  데이터"
              to="/humidity"
              icon={<WaterRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[ 3794, 3902, 4535, 4643, 4818, 4822, 4873, 4877 ]}
            />
            <Item
              title="온도  데이터"
              to="/temperature"
              icon={<DeviceThermostatRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[ 3734, 3740, 4703, 4709, 4830, 4835, 4854, 4859 ]}
            />
            <Item
              title="진동  데이터"
              to="/vibration"
              icon={<VibrationRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[ 3963, 3964, 4810, 4816 ]}
            />

            {/* 기타 페이지 */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="기타 문서"
              to="/others"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              isolated={isolation}
              compList={[]}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
