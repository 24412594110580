import { Box, useTheme, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../contexts/theme";

const Others = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box height="620px">
      <Accordion style={{ backgroundColor: colors.grey[900] }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[400]} variant="h5">
            테스트 안내 메시지 1
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>테스트용 안내 메시지 1 입니다.</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ backgroundColor: colors.grey[900] }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[400]} variant="h5">
            테스트 안내 메시지 2
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>테스트용 안내 메시지 2 입니다.</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Others;
