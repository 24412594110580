// import { ResponsiveLine } from "@nivo/line";
// import { useTheme } from "@mui/material";
// import { tokens } from "../contexts/theme";

const DataSet = (data, is_dashboard) => {
  return ("Previous Data")
  // return ("DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part DataSet part");
};

export default DataSet;
