import { Box, useTheme } from "@mui/material";
import { tokens } from "../../contexts/theme";
import FormatLineChart from "../../visualizations/FormatLineChart";
import DataSet from "../../forge/DataSet";

const Humidity = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      className="det-graph"
      display="flex"
      flexDirection="column"
      // display="grid"
      // gridTemplateColumns="repeat(12, 1fr)"
      // gridAutoRows="230px"
      // gap="20px"
    >
      {/* Row 1 */}
      <Box
        gridColumn="span 12"
        gridRow="span 3"
        backgroundColor={colors.primary[400]}
      >

        <Box height="720px" m="-10px -20px 0 0">
          {data && (
            <FormatLineChart
              data={data.Humidity}
              is_dashboard={false}
              y_label={"Humidity"}
              boundary={{min:20, max:40}}
            />
          )}
        </Box>
      </Box>
      <Box 
        className="det-graph-data"
        backgroundColor={colors.primary[400]}
      >
        <DataSet data={data.Humidity} />
      </Box>
    </Box>
  );
};

export default Humidity;
