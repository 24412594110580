import React, { useEffect, useCallback } from "react";
import { initViewer } from "./viewer.js";
import { setupModelSelection } from "./main.js";
// import { Box, useTheme } from "@mui/material";
// import { useTheme } from "@mui/material";
// import { tokens } from "../contexts/theme.js";
import "./main.css";

function ViewerComponent({ setViewController }) {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);

  const initializeViewer = useCallback(() => {
    const Autodesk = window.Autodesk;
    initViewer(document.getElementById("preview"), Autodesk).then((viewer) => {
      const urn = window.location.hash?.substring(1);

      setViewController(viewer);

      setupModelSelection(viewer, urn);
    });
    setViewController();
  }, [setViewController]);

  // Viewer 초기화 및 모델 목록 로드
  useEffect(() => {
    initializeViewer();
    // const Autodesk = window.Autodesk;
    // initViewer(document.getElementById("preview"), Autodesk).then((viewer) => {
    //   const urn = window.location.hash?.substring(1);

    //   setViewController(viewer);

    //   setupModelSelection(viewer, urn);
    // });
  }, [initializeViewer]);

  return (
    <div className="3d-graph">
      <div id="preview"></div>
      <div id="overlay"></div>
    </div>
  );
}

export default ViewerComponent;
