import { loadModel } from "./viewer.js";

export async function setupModelSelection(viewer) {
  let target_model = {};
  try {
    const resp = await fetch("http://localhost:8787/api/models", {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!resp.ok) {
      throw new Error(await resp.text());
    }
    const models = await resp.json();

    // console.log(models);
    target_model = models[0];
    // console.log("target = ", target_model);
    // console.log("target urn=", target_model.urn);
    onModelSelected(viewer, target_model.urn);

  } catch (err) {
    alert("Could not list models. See the console for more details.");
    console.error(err);
    return target_model;
  }
  return target_model;
}

async function onModelSelected(viewer, urn) {
  if (window.onModelSelectedTimeout) {
    clearTimeout(window.onModelSelectedTimeout);
    delete window.onModelSelectedTimeout;
  }
  window.location.hash = urn;
  try {
    const resp = await fetch(`http://localhost:8787/api/models/${urn}/status`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!resp.ok) {
      throw new Error(await resp.text());
    }
    const status = await resp.json();
    switch (status.status) {
      case "n/a":
        showNotification(`Model has not been translated.`);
        break;
      case "inprogress":
        showNotification(`Model is being translated (${status.progress})...`);
        window.onModelSelectedTimeout = setTimeout(
          onModelSelected,
          5000,
          viewer,
          urn
        );
        break;
      case "failed":
        showNotification(
          `Translation failed. <ul>${status.messages
            .map((msg) => `<li>${JSON.stringify(msg)}</li>`)
            .join("")}</ul>`
        );
        break;
      default:
        const Autodesk = window.Autodesk;
        clearNotification();
        loadModel(viewer, urn, Autodesk);
        break;
    }
  } catch (err) {
    alert("Could not load model. See the console for more details.");
    console.error(err);
  }
}

function showNotification(message) {
  const overlay = document.getElementById("overlay");
  overlay.innerHTML = `<div class="notification">${message}</div>`;
  overlay.style.display = "flex";
}

function clearNotification() {
  const overlay = document.getElementById("overlay");
  overlay.innerHTML = "";
  overlay.style.display = "none";
}
