import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../contexts/theme";

const FormatLineChart = ({ data, is_dashboard, y_label, boundary }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveLine
      // 부모 컴포넌트로부터 props를 받아서 적용
      data={data}
      enablePoints={is_dashboard ? false : true}
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      enablePointLabel={true}
      margin={
        is_dashboard
          ? {
              bottom: 50,
              left: 60,
              right: 60,
              top: 50,
            }
          : {
              bottom: 80,
              left: 60,
              right: 60,
              top: 60,
            }
      }
      axisBottom={
        is_dashboard
          ? null
          : {
              format: "%H:%M:%S",
              legend: "time scale",
              legendOffset: 36,
              legendPosition: "middle",
            }
      }
      yScale={boundary
        ? {type: "linear",min: boundary.min,max: boundary.max,}
        : {type: "linear",min: "auto",max: "auto",}
      }
      legends={
        is_dashboard
          ? []
          : [
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 30,
                translateY: 70,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
      // 색상 적용
      theme={{
        text: {
          fill: colors.grey[100],
        },
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip:{
          container:{
            color: "#141414",
          }
        }
      }}
      xScale={{
        format: "%Y-%m-%d %H:%M:%S",
        precision: "second",
        type: "time",
        useUTC: true,
      }}
      xFormat="time:%Y-%m-%d %H:%M:%S"
      yFormat=">-.2f"
      axisLeft={{
        legend: y_label,
        legendPosition: "middle",
        legendOffset: -40,
      }}
      pointSize={5}
      pointBorderWidth={2}
      pointBorderColor={{
        from: "serieColor",
      }}
      useMesh={true}
      colors={{ scheme: "red_grey" }}
      Hello
    />
  );
};

export default FormatLineChart;
