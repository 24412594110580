import Header from "./header";

const OthersHeader = () => {
  const title_str = "OTHERS";
  const subtitle_str = [`기타 안내를 위한 페이지를 별도록 구성했습니다.`];

  return <Header title={title_str} subtitle={subtitle_str} />;
};

export default OthersHeader;
