import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../contexts/theme";
import FormatLineChart from "../../visualizations/FormatLineChart";

const Dashboard = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div className="main-dashboard">
      {/* 첫번째 박스 */}
      <Box className="main-box" backgroundColor={colors.primary[400]}>
        <Box className="main-box-detail">
          <Box>
            <Typography variant="h3" fontWeight="600" color={colors.grey[100]}>
              공기질 센서 데이터
            </Typography>
            <Typography variant="h6" fontWeight="500" color={colors.greenAccent[400]}>
              총 8개 센서로부터 데이터를 받습니다.
            </Typography>
          </Box>
        </Box>
        <Box className="main-box-graph" m="-30px -20px 0 0">
          {data && (
            <FormatLineChart
              data={data.AirQuality}
              is_dashboard={true}
              y_label={"Air Quality"}
            />
          )}
        </Box>
      </Box>
      {/* 두번째 박스 */}
      <Box className="main-box" backgroundColor={colors.primary[400]}>
        <Box className="main-box-detail">
          <Box>
            <Typography variant="h3" fontWeight="600" color={colors.grey[100]}>
              태양광 발전량
            </Typography>
            <Typography variant="h6" fontWeight="500" color={colors.greenAccent[400]}>
              총 2개 센서로부터 데이터를 받습니다.
            </Typography>
          </Box>
        </Box>
        <Box className="main-box-graph" m="-30px -20px 0 0">
          {data && (
            <FormatLineChart
              data={data.SolarEnergy}
              is_dashboard={true}
              y_label={"Solar Energy"}
            />
          )}
        </Box>
      </Box>
      {/* 세번째 박스 */}
      <Box className="main-box" backgroundColor={colors.primary[400]}>
        <Box className="main-box-detail">
          <Box>
            <Typography variant="h3" fontWeight="600" color={colors.grey[100]}>
              진동 센서 데이터
            </Typography>
            <Typography variant="h6" fontWeight="500" color={colors.greenAccent[400]}>
              총 4개 센서로부터 데이터를 받습니다.
            </Typography>
          </Box>
        </Box>
        <Box className="main-box-graph" m="-30px -20px 0 0">
          {data && (
            <FormatLineChart
              data={data.Vibration}
              is_dashboard={true}
              y_label={"Vibration"}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
