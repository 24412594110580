import { BaseExtension } from "./BaseExtension.js";

class LoggerExtension extends BaseExtension {
  load() {
    super.load();
    console.log("LoggerExtension loaded.");
    return true;
  }

  unload() {
    super.unload();
    console.log("LoggerExtension unloaded.");
    return true;
  }

  async onModelLoaded(model) {
    super.onModelLoaded(model);
    const props = await this.findPropertyNames(this.viewer.model);
    console.log(
      "New model has been loaded. Its objects contain the following properties:",
      props
    );
  }

  async onSelectionChanged(model, dbids, mainViewer) {
    super.onSelectionChanged(model, dbids);
    console.log("이벤트 onSelectionChanged", dbids);

    const ids = mainViewer.getSelection();
    if (ids.length === 1) {
      
      model.getBulkProperties(dbids, {}, function (results) {
        // let propNames = new Set();
        let props = [];
        for (const result of results) {
          for (const prop of result.properties) {
            // propNames.add(prop.displayName);
            props.push({ name: prop.displayName, value: prop.displayValue });
          }
        }
        console.log("model.getBulkProperties: ", props);
      });
    }
  }

  onIsolationChanged(model, dbids) {
    super.onIsolationChanged(model, dbids);
    console.log("이벤트 onIsolationChanged", dbids);
  }
}

export async function initExtensions(Autodesk) {
    console.log("Init Extension 실행");
  await Autodesk.Viewing.theExtensionManager.registerExtension(
    "LoggerExtension",
    LoggerExtension
  );
}
