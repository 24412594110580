import { useState, useEffect } from "react";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../contexts/theme";

const TimeBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); //1000
    return () => clearInterval(intervalID);
  }, []);

  return (
    <Typography
      variant="h6"
      color={colors.grey[300]}
      fontWeight="light"
      sx={{ m: "10px 0 0 5px" }}
    >
      {currentDate.toLocaleString()}
    </Typography>
  );
};

export default TimeBar;
