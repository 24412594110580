import {initExtensions} from "./extensions/LoggerExtension.js"
import {initWindowExtensions} from "./extensions/WindowPanel.js"

async function getAccessToken(callback) {
  try {
    const resp = await fetch("http://localhost:8787/api/auth/token", {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!resp.ok) {
      throw new Error(await resp.text());
    }
    const { access_token, expires_in } = await resp.json();
    callback(access_token, expires_in);
  } catch (err) {
    alert("Could not obtain access token. See the console for more details.");
    console.error(err);
  }
}

export function initViewer(container, Autodesk) {
  return new Promise(function (resolve, reject) {
    initWindowExtensions(Autodesk);
    initExtensions(Autodesk);
    Autodesk.Viewing.Initializer({ getAccessToken }, function () { // autodesk forge 화면 표시 가능한지 확인
      const config = {
        extensions: ["Autodesk.DocumentBrowser", 'WindowExtension', "LoggerExtension"],
      };
      const viewer = new Autodesk.Viewing.GuiViewer3D(container, config);
      viewer.start();
      viewer.setTheme("light-theme");
      resolve(viewer);
    });
  });
}

export function loadModel(viewer, urn, Autodesk) {
  return new Promise(function (resolve, reject) {
    function onDocumentLoadSuccess(doc) {
      resolve(viewer.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry()));
    }
    function onDocumentLoadFailure(code, message, errors) {
      reject({ code, message, errors });
    }
    viewer.setLightPreset(0);
    Autodesk.Viewing.Document.load(
      "urn:" + urn,
      onDocumentLoadSuccess,
      onDocumentLoadFailure
    );
  });
}
