// import * as THREE from 'https://cdn.jsdelivr.net/npm/three/build/three.module.js';
import * as THREE from 'three';

const Autodesk = window.Autodesk;

class WindowExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);
        this.viewer = viewer;
        this.ui = null;
        this.panel = null;

        this.defaultState = null;
        // this.tree = Autodesk.Viewing.InstanceTree;
        // this.tree = this.viewer.getObjectTree;
        // this.tree = this.viewer.addEventListener(Autodesk.Viewing.BEFORE_MODEL_UNLOADED, this.viewer.model);
        // this.tree = this.viewer.model.getData().instanceTree;
        // console.log(this.tree);

        this.customize = this.customize.bind(this);
        this.getObjectTree = this.getObjectTree.bind(this);
        this.createUI = this.createUI.bind(this);
        this.setTransformation = this.setTransformation.bind(this);

        this.getFragmentWorldMatrixByNodeId = this.getFragmentWorldMatrixByNodeId.bind(this);
        // this.findNodeIdbyName = this.findNodeIdbyName.bind(this);

        // 창문 ID
        // this.leftwindow01 = null;
    }

    load() { //av is Autodesk.Viewing
        console.log('WindowExtension is loaded!');
        
        this.customize();
        this.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.getObjectTree);
        this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.createUI);
        this.setTransformation();

        return true;
    }
    unload() {
        console.log('WindowExtension is now unloaded!');
        this.viewer.restoreState(this.defaultState);
        return true;
    }

    getObjectTree(){
        this.viewer.getObjectTree(function(tree){
            console.log("object tree found");
        }, function(err){
            console.error("object tree error", err);
        });
        // console.log(this.tree);
    }

    customize() {
        this.defaultState = this.viewer.getState();
        // this.viewer.restoreState(someViewerState);
        this.tree = Autodesk.Viewing.Private.InstanceTree;
        // console.log(this.tree);

        // this.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.getObjectTree);
        // this.tree= "aksdhals";
        // this.viewer.loadExtension('Autodesk.NPR');
        // var ext = this.viewer.getExtension('Autodesk.NPR');
        // ext.setParameter("style", "edging");
        // ext.setParameter("depthEdges", false);
        this.viewer.setBackgroundColor(255,255,255,255,255,255);
        this.viewer.setGroundShadow(false);
        this.viewer.setGroundReflection(true);
        this.viewer.setTheme("light-theme");
    }

    setTransformation(){

        // let Rightwindow1 = 27927;

        /* ====================== right 0 ================= */
        // let ID1 = 27927;
        // let ID_BaseRod = 4846;
        // let ID_LowerArmBody = 4846;

        // console.log(this.tree);

        // let Pivot_BaseRod = new THREE.Mesh(new THREE.BoxGeometry(0.1, 0.1, 0.1), new THREE.MeshBasicMaterial({ color: 0xff0000 }));
        // let Position_BaseRod = this.getFragmentWorldMatrixByNodeId(ID1).matrix[0].getPosition().clone();

        // print the returned value from getFragmentWorldMatrixByNodeId method and using this we can inspect the....
        // output of the fucntion(method) whether working or not
        // console.log(Position_BaseRod);
        // Pivot_BaseRod.position.x = 0;
        // Pivot_BaseRod.position.y = Position_BaseRod.y;
        // Pivot_BaseRod.position.z = Position_BaseRod.z;
        // this.viewer.impl.scene.add(Pivot_BaseRod);

        // let Helper_LowerArmBody = new THREE.Mesh();
        // let Position_LowerArmBody = this.getFragmentWorldMatrixByNodeId(ID_LowerArmBody).matrix[0].getPosition().clone();
        // Helper_LowerArmBody.position.x = - Position_LowerArmBody.x + Math.abs(Position_LowerArmBody.x - Pivot_BaseRod.position.x);
        // Helper_LowerArmBody.position.y = - Position_LowerArmBody.y + Math.abs(Position_LowerArmBody.y - Pivot_BaseRod.position.y);
        // Helper_LowerArmBody.position.z = - Position_LowerArmBody.z + Math.abs(Position_LowerArmBody.z - Pivot_BaseRod.position.z);
        // Pivot_BaseRod.add(Helper_LowerArmBody);
        // console.log(Position_LowerArmBody);

        /* ====================== Link to controls ================= */
        // let rightControlSlider = document.getElementById("RightWindow");
        // let leftControlSlider = document.getElementById("LeftWindow");
        // let allControlSlider = document.getElementById("AllWindow");
        // let reset_button = document.getElementById("wing-reset");
        
        

        // let windown_button = dp
        // rightControlSlider.oninput = (event) => {
        //     console.log("Right is moving");
        // };

        // leftControlSlider.oninput = (event) => {

        // };

        // allControlSlider.oninput = (event) => {

        // };

        // reset_button.onclick = (event) => {
        //     console.log("Clicked reset-button");
        // };

    }

    getFragmentWorldMatrixByNodeId(nodeId) {
        let viewer = this.viewer;

        let result = {
            fragId: [],
            matrix: [],
        };
        this.tree.enumNodeFragments(nodeId, function (frag) {

            let fragProxy = viewer.impl.getFragmentProxy(viewer.model, frag);
            let matrix = new THREE.Matrix4();

            fragProxy.getWorldMatrix(matrix);

            result.fragId.push(frag);
            result.matrix.push(matrix);
        });
        // console.log("this.getFragmentWorldMatrixByNodeId");
        return result;
    }

    RightWindowSet(){

    }

    LeftWindowSet(){

    }

    AllWindowSet(){

    }

    ResetWindow(){
        
    }









    //loggerextensnion에서 숫자(id) 가져와서 하기

    createUI() {

        this.ui = document.createElement("div");
        this.ui.id = "window_det";
        this.ui.classList.add("addedItem");
        this.ui.innerHTML = `
            <div id="controlsArea">
                <div><span>Right wing: </span><input type="range" min="0" max="100" value="0" class="slider" id="RightWindow"></div>
                <div><span>Left Wing: </span><input type="range" min="0" max="100" value="0" class="slider" id="LeftWindow"></div>
                <div><span>All: </span><input type="range" min="0" max="100" value="0" class="slider" id="AllWindow"></div>
                <div><button type="button" id="wing-reset">Reset</button></div>
            </div>
        `;

        let panel = this.panel;
        let viewer = this.viewer;
        
        let toolbarButtonWindow = new Autodesk.Viewing.UI.Button('WindowControl');

        if (panel == null) {
            panel = new WindowControlPanel(viewer, viewer.container,
                'controlPanel', 'Window Control Panel', {"innerDiv":this.ui});
        }

        toolbarButtonWindow.onClick = (e) => {
            panel.setVisible(!panel.isVisible());
            if(panel.isVisible()){
                console.log("panel is visible now!!!!");
                // 창문 설정
                // 오른쪽 창문 움직이기
                // 왼쪽 창문 움직이기
                // 모든 창문 움직이기
                // reset버튼 누르고 자동으로 창문 움직이기
            }
        };

        toolbarButtonWindow.addClass('toolbarButtonWindow');
        toolbarButtonWindow.setToolTip('Show/Hide Window Controls');

        // SubToolbar
        this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('ExtensionWindowControlToolbar');
        this.subToolbar.addControl(toolbarButtonWindow);
        this.viewer.toolbar.addControl(this.subToolbar);
    }
}

// Autodesk.Viewing.theExtensionManager.registerExtension('WindowExtension', WindowExtension);
export async function initWindowExtensions(Autodesk) {
  await Autodesk.Viewing.theExtensionManager.registerExtension('WindowExtension', WindowExtension);
}

// *******************************************
// Window Control Panel
// *******************************************
function WindowControlPanel(viewer, container, id, title, options) {
    this.viewer = viewer;

    Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

    this.container.classList.add('addedItem');
    this.container.id = "windowControlPanelContainer";

    this.container.appendChild(options.innerDiv);
}
WindowControlPanel.prototype = Object.create(Autodesk.Viewing.UI.DockingPanel.prototype);
WindowControlPanel.prototype.constructor = WindowControlPanel;


// const someViewerState = {
//     "seedURN": "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6cGs5Ymt1YW03YjFnNjljeXduanQ5NnBsYmZ2bWl4cGpnYmwyYXo1c3d6MTFuNmpyLXR1bm5lbC9leGFtcGxlLnJ2dA",
//     "objectSet": [
//         {
//             "id": [],
//             "isolated": [],
//             "hidden": [],
//             "explodeScale": 0,
//             "idType": "lmv"
//         }
//     ],
//     "viewport": {
//         "name": "",
//         "eye": [
//             204.48330126505937,
//             100.3164039523009,
//             182.73839807077565
//         ],
//         "target": [
//             1.0011920928955078,
//             2.052783966064453,
//             0
//         ],
//         "up": [
//             -0.25157238314283936,
//             0.9410997291372207,
//             -0.2259261734675335
//         ],
//         "worldUpVector": [
//             0,
//             1,
//             0
//         ],
//         "pivotPoint": [
//             1.0011920928955078,
//             2.052783966064453,
//             0
//         ],
//         "distanceToOrbit": 290.6097553342371,
//         "aspectRatio": 1.3423517169614985,
//         "projection": "perspective",
//         "isOrthographic": false,
//         "fieldOfView": 22.61986532341139
//     },
//     "renderOptions": {
//         "environment": "(Custom: Model defined)",
//         "ambientOcclusion": {
//             "enabled": true,
//             "radius": 5.945260721973842,
//             "intensity": 0.4
//         },
//         "toneMap": {
//             "method": 1,
//             "exposure": -8.974,
//             "lightMultiplier": -1e-20
//         },
//         "appearance": {
//             "ghostHidden": true,
//             "ambientShadow": true,
//             "antiAliasing": true,
//             "progressiveDisplay": true,
//             "swapBlackAndWhite": false,
//             "displayLines": true,
//             "displayPoints": true
//         }
//     },
//     "cutplanes": []
// };