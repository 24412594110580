import Header from "./header";

const DashboardHeader = () => {
  const title_str = "DASHBOARD";
  const subtitle_str = [`모니터링 시스템을 요약적으로 보여주는 화면입니다. 데이터는 서버로부터 5초마다 새롭게 받습니다. 해당 데이터는 시계열 데이터로서, x축은 과거 30초부터 현재 시간까지를 나타냅니다.`];

  return <Header title={title_str} subtitle={subtitle_str} />;
};

export default DashboardHeader;
